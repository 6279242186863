import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Link } from 'gatsby'
import BgBlock from '../components/bg-block'
import Layout from '../components/layout/layout'
import '../styles/main.scss'
import whitepaper from '../assets/whitepaper.pdf'
import TTPercent from '../assets/logo.svg'

// markup
const WhitepaperPage = () => (
  <Layout
    isHomePage={false}
    metaTitle="Whitepaper"
    metaDescription="Whitepaper"
    className="whitepaper"
  >
    {/* <BgBlock color="black" backgroundColor="#BAE1E8">
      <h2>Coming soon!</h2>
    </BgBlock> */}
    <BgBlock
      className="logo--black"
      color="#000"
      backgroundColor="var(--bg-block-bg-color-light)"
    >
      <h1 className="hidden-label">22%</h1>
      <div className="logo--black">
        <TTPercent style={{ width: 200 }} />
      </div>
      <h2>An NFT Project that saves lives</h2>

      {/* <StaticImage
        src="../images/whitepaper/1.png"
        width={500}
        alt="NFT"
        placeholder="blurred"
      /> */}

      <h3>1 Introduction</h3>
      <p>
        Welcome to the 22% NFT Whitepaper. We are glad to see that you are
        investing your time to learn more about the project and explore its
        potential. With this official statement, we would like to walk you
        through the decision process that brought to the creation of 22%,
        showing the vision behind it and highlighting the roadmap the Team has
        planned for the future of our NFTs. We wish you enjoy reading through
        all the explanatory sections of our project, and hope you will get some
        insights about our short- and long-term objectives Our deep desire, is
        that you worm out the added value that this project can bring to its
        holders and even more to the society.
      </p>
      <h4>1.1 The Blockchain Context</h4>
      <p>
        Providing security for investment in digital art was made possible by
        the development of technologies such as the Ethereum Blockchain.
        Publishing a digital collectibles collection on the Blockchain can
        therefore demonstrate authenticity, proof the ownership and verify the
        scarcity of the NFTs.
      </p>
      <p>
        So far, most of the NFT projects have been minted on a single
        Blockchain, the most famous being the Ethereum one. As we tend to think
        out of the box, we wish to launch the collection on two different
        chains, namely on the aforementioned Ethereum and on Solana. This, in
        our opinion, presents a substantial advantage. A collection on multiple
        chains aims to reach a wider number of users. More collectors and donors
        will therefore be involved by having the possibility to invest in our
        project depending on their preferred ecosystem.
      </p>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}>
        <a className="button" href={whitepaper} download="whitepaper.pdf">
          Download Whitepaper
        </a>
      </div>
    </BgBlock>

    <BgBlock color="#000" backgroundColor="var(--bg-block-bg-color-dark)">
      <h3>2 The Vision</h3>
      <p style={{ textAlign: 'center' }}>
        <q style={{ fontStyle: 'italic' }}>
          If you are working on something exciting that you really care about,
          you don’t have to be pushed. The vision pulls you.
        </q>{' '}
        - Steve Jobs
      </p>
      <p>
        Yes, this man was right. Having a clear vision is a driving force to
        bring excitement to your journey and pave the way to success. We believe
        in our project and in all the people involved in it. That is one of our
        strengths, that keeps the Team motivated and gives us the energy to make
        the difference out there.
      </p>
      <p>
        In the next subsections, we will present you our vision for the future
        of the project, trying to engage your hearth and your investor soul.
      </p>

      <h4>2.1 Create Awareness</h4>
      <p>
        We are living in the 21<sup>st</sup> century, and have just experienced
        a pandemic that dramatically changed the life of billions of humans.
        While a good majority of the society is struggling with it, some tend to
        forget that a large part of the population is fighting daily against
        diseases of different severity, both from a physical and psychological
        point of view.
      </p>
      <p>
        Let’s look at some examples. Do you know how many women have breast
        cancer? And how many people are smoke addicted? We do! But most
        importantly, we do want to share this knowledge to a global audience.
        Our main goal is in fact to create awareness about the heterogeneity of
        the population and some of the most representative pathologies it is
        facing in the modern society. Because in the real world, as well as in
        the Metaverse, there will always be a downside related to the people’s
        health.
      </p>
      <p>
        By highlighting this aspect in the crypto space, we are pushing for an
        NFT collection that virtually represents and mirrors the reality. In a
        time when the Metaverse is expanding and everything is getting virtual,
        let’s avoid loosing the overview on our human society, and try to do
        good for the right cause.
      </p>

      <h4>2.2 The Design Approach</h4>
      <p>
        The above mentioned principle is the reason why most of the attributes
        and traits representing the design of our collectibles will be based on
        percentages that represent the reality. Let’s illustrate a basic
        example. 0.08% of the population suffers from Diabetes of type 2,
        therefore 8 out of 10, 000 of our NFTs will present a disease bubble on
        the bottom right corner of the image, representing the before mentioned
        condition. Such a concept is illustrated in Figure 1. By randomizing a
        set of prescribed traits based on statistics and carefully analyzed by
        our Team, a set of 10, 000 unique pieces with different rarities was
        generated. A list of all possible traits and designs will be published
        in further versions of the Whitepaper, together with the source of
        percentages of each attribute. You can also find all project-related
        relevant infos on our <Link to="/">Official Website</Link>.
      </p>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <StaticImage
          style={{ maxWidth: 500 }}
          src="../images/whitepaper/2.png"
          width={500}
          alt="NFT"
          placeholder="blurred"
        />
        <caption>
          Figure 1: Example of a randomized NFT affected by Diabetes of type 2.
        </caption>
      </div>

      <h4>2.3 Save Lives</h4>
      <p>
        We are aware that we can’t save every life that is in danger, but we
        know that we can act proactively to try and save some of them, making
        the difference with the little we have. How exactly? The force lays in
        the numbers. That’s why we want to create a community that drives others
        to join the movement and donate through our platform. This projects is
        not only about collecting little humanoid pieces of digital art. It is
        about contributing to the well being of people thanks to the impact of
        the community. Not surprisingly, we will donate 22% of our primary sale
        and up to 99% of our royalties to carefully selected organizations. This
        reflects our vision of saving lives by transparently supporting
        organizations, created by the people, for the people.
      </p>

      <h4>2.4 Transparent Charity</h4>
      <p>
        Yes, we know we are not reinventing the wheel by devoting to verified
        organizations. Nonetheless, we believe that transparent charity is a key
        point for a successful development of our plans. We want to donate to
        organizations that help people giving them new perspectives in a
        transparent way, using platforms such as &ldquo;The Giving Block&ldquo;{' '}
        <a className="footnote-link" href="#footnote1">
          1
        </a>{' '}
        as much as possible and making all our donation transaction IDs public.
        We will always keep the community up to date about our next actions,
        involving them into decision process to decide the organization we shall
        donate to.
      </p>

      <p className="footnote" id="footnote1">
        [1]{' '}
        <a
          className="link"
          rel="nofollow noopener noreferrer"
          target="_blank"
          href="https://thegivingblock.com/"
        >
          The Giving Block
        </a>
      </p>

      <h4>2.5 The Name</h4>
      <p>
        You might be wondering, where does 22% come from? To be honest there is
        no specific reason behind this number, but let’s motivate the choice.
        Our founder woke up one day with a visionary idea. Of course, dreams are
        made of thoughts, images, facts, but sometimes they do also consist of
        plans, charts and numbers. And if you are reading this Whitepaper, you
        have something in common with us: you like numbers. Now just add some
        spices from our technology- and science-oriented background:
        percentages. Statistics represent a great way to characterize the way
        the community is organized and subdivided. As a consequence, they are
        well suited to generate awareness about rare traits that compose the
        society.
      </p>
      <p>
        Well, 22 ends up being a nice number indeed. First of all, it represents
        the year of launch of the project. But more importantly, we decided that
        22% will be also the percentage of donation in the primary sale and
        10.22% will be the royalties on the second market. The ultimate goal is
        to increase the amount of royalties we devolve as a contribution of 2.5%
        each quarter. This would enable us to donate 99% of all project incomes
        to verified charity funds by 2030.
      </p>
    </BgBlock>

    <BgBlock color="#000" backgroundColor="var(--bg-block-bg-color-light)">
      <h3>3 Roadmap</h3>
      <p>
        Lately, we have been seeing a lot of projects launching in the NFT space
        in a really short period of time. Rushing for a milestone is never a
        good idea when the work is lacking in consistency in the used strategy
        and in some valid background. As a consequence, most of the projects out
        there are doomed to fail in the medium to long timescale. Our goal is to
        create a solid project that will gain value and add value to many people
        in need in the long term. To this end, and thanks to our Team, we are
        trying to set up strong fundamentals that will endure the passing of
        time. We are working on the project since September 2021 and we are
        effectively the first NFT collection that dropped in 2022.
      </p>

      <h4>3.1 The Drop</h4>
      <p>
        To be effectively the first collection going live in 2022, the presale
        of the Ethereum collection was launched on Friday, 31<sup>th</sup> of
        December 2021, 10 : 00 UTC. The adopted launching strategy follows the
        reasoning that the first portion of the eastern world will already be in
        the new year. The actual drop on both the Ethereum and Solana collection
        will launch on the 3<sup>rd</sup> of April 2022, 17 : 00 UTC. This is
        representing the first multi-chain collection of the year.
      </p>
    </BgBlock>

    <BgBlock color="#000" backgroundColor="var(--bg-block-bg-color-dark)">
      <h3>4 The Beneﬁts</h3>
      <p>
        We have planned several ways to interact with the community and thank
        our people with benefits and further developments of the project.
        <ul>
          <li>
            Members of the 22% community will be able to directly influence the
            charity process by voting among carefully selected associations;
          </li>
          <li>
            Holders of 2 NFTs (female and male) will be able to breed and obtain
            a kid;
          </li>
          <li>
            Holders of 2 NFTs of the same gender, will be able to adopt a kid
            for an amount that will be completely devolved to children’s homes;
          </li>
          <li>
            After the reach of multiple sales milestones, a considerable amount
            of collectibles will be airdropped to random holders;
          </li>
          <li>Some of the early holders will receive a print of their NFT;</li>
          <li>
            Members will have primary access to future developments, such as 3D
            models of their own assets in the Metaverse and the pre-sale of the
            project’s coin $22PC;
          </li>
          <li>
            Holders will be able to drive financial decisions regarding the
            digital art investment found of the 22% Team. Please refer to the
            next Chapter for a better understanding of the 22% investment found;
          </li>
          <li>
            Following the launch on the Solana Blockchain, we will airdrop 2
            access tickets for the Digital Health Congress of 2022;
          </li>
          <li>
            To all of you. Listen to Sophocles’ quote from more than 2000 years
            ago:{' '}
            <q style={{ fontStyle: 'italic' }}>
              To be doing good deeds is man’s most glorious task.
            </q>
            Although you might be interested in money returns, keep in mind that
            donating to the right cause will give you back much more than that.
          </li>
        </ul>
      </p>

      <h3>4.1 The 22% Investment Fund</h3>
      <p>
        The 22% Investment Fund is meant to be a portion of the 22% project
        incomes that gets reinvested into different types of digital art. 10.22%
        of the primary sales on both Solana and Ethereum will be used to buy
        into blue chips and promising NFT projects. Gains made with the Fund
        after the sale of the acquired NFTs will be redistributed in the
        following way:
        <ul>
          <li>50% will be given back to the holders;</li>
          <li>33% will be donated to the selected organizations;</li>
          <li>10% will be further reinvested;</li>
          <li>7% will redistributed between the team members.</li>
        </ul>
      </p>
    </BgBlock>

    <BgBlock color="#000" backgroundColor="var(--bg-block-bg-color-light)">
      <h3>5 Conclusion</h3>
      <p>
        22% is an NFT project founded to make the difference and save lives.
        Thanks to our clear vision, and counting on a competent Team, we aim to
        stay out there in the future and play the long game. Creating awareness
        about the diversity of the society is our calling. Donating to those who
        are differently-abled is our mission. In the journey of life, we are all
        together on the same ship. Let’s try to do good by looking after each
        other!
      </p>
    </BgBlock>

    <BgBlock color="#000" backgroundColor="var(--bg-block-bg-color-dark)">
      <h3>Disclaimer</h3>
      <p>
        This Whitepaper does not represent by any means any sort of advice
        regarding the purchase of 22% NTFs, and should therefore not be intended
        as such. By investing in our product, you face your own decision. You
        must therefore conduct your own due diligence to ensure you comply with
        all local cryptocurrency-related laws and other regulations in your
        jurisdiction. To this end, we encourage you to independently consult
        professional advisors.
      </p>
      <p>
        To differentiate our NFTs, we based on several public sources that
        publish statistical analysis of the worlds population. After carefully
        studying different strategies for the diversification of our avatars, we
        came to the conclusion that gathering data from all over the world would
        have been practically very difficult. Therefore, for those traits for
        which we faced non-reliable or missing statistics, we decided to go for
        a statistical sample that represents to a higher extend the western
        society. However, we can not guarantee the absolute accuracy of the
        data, since those are very time- and region-related.
      </p>
    </BgBlock>
  </Layout>
)

export default WhitepaperPage
